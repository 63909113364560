<template>
    <div class="focus-page" :class="{'pb-0': viewType == 'kanban'}">
        <div class="row-space-tbf">
            <div class="space-left"></div>
            <div class="content">
                <div class="header-views">
                    <div class="view-item" :class="{active: viewType == 'document'}" @click="changeView('document')">
                        <div class="icon">
                            <icon-projects />
                        </div>
                        <div class="text">{{ $t('projects.mode_views.document') }}</div>
                    </div>
                    <div class="view-item" :class="{active: viewType == 'kanban'}" @click="changeView('kanban')">
                        <div class="icon rotate-180">
                            <icon-kanban />
                        </div>
                        <div class="text">{{ $t('projects.mode_views.kanban') }}</div>
                    </div>

                    <div class="view-item settings" @click="showModal('advanced_settings_project', { slug: $route.params.slug })">
                        <div class="icon">
                            <icon-settings />
                        </div>
                        <div class="text">{{ $t('projects.advanced_settings') }}</div>
                    </div>
                </div>
            </div>
            <div class="space-right"></div>
        </div>

        <ViewDocument v-if="viewType == 'document'"/>
        <ViewKanban v-if="viewType == 'kanban'"/>
    </div>
</template>

<script>
import ViewDocument from "./Views/Document";
import ViewKanban from "./Views/Kanban";
import IconProjects from "@/components/Icons/Projects";
import IconKanban from "@/components/Icons/Kanban";
import IconSettings from "@/components/Icons/SettingsGear"

export default {
    components: {
        ViewDocument,
        ViewKanban,
        IconProjects,
        IconKanban,
        IconSettings
    },
    data() {
        return {
            loaded: true,
            viewType: 'document'
        };
    },
    created() {
        // Check for the viewType query parameter and set the viewType data property
        const viewType = this.$route.query.viewType;
        if (viewType) {
            this.viewType = viewType;
        }
    },
    computed: {
        
    },
    beforeDestroy () {

    },
    mounted() {
    
    }, 
    methods: {
        changeView(modeView) {
            this.viewType = modeView;
            this.$router.push({ query: { viewType: modeView } });
        },
        showModal(type, data = false, itemId = false){
            this.$root.$emit('open_modal', type, data, itemId);
        }
    },
};
</script>